(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

$(window).on('resize scroll', function () {
  if ($(window).scrollTop() >= 40 && $(window).innerWidth() >= 992) {
    $('.metro-nav').addClass('locked');
    $('.nav-toggler').addClass('locked');
  } else if ($(window).scrollTop() >= 80 && $(window).innerWidth() <= 992) {
    $('.metro-nav').addClass('locked');
    $('.nav-toggler').addClass('locked');
  } else {
    $('.metro-nav').removeClass('locked');
    $('.nav-toggler').removeClass('locked');
  }

  if ($(window).scrollTop() >= 320 && $(window).innerWidth() >= 992) {
    $('.desktop-logo').addClass('show-logo');
  } else {
    $('.desktop-logo').removeClass('show-logo');
  }
});

$(function () {
  $('.nav-toggler').on('click', function () {
    $('.nav-toggler').toggleClass('open');
    $('.metro-nav').toggleClass('active');
  });

  $('.nav-link').on('click', function () {
    if ($('.nav-toggler').hasClass('open')) {
      $('.nav-toggler').removeClass('open');
    }
    if ($('.metro-nav').hasClass('active')) {
      $('.metro-nav').removeClass('active');
    }
  });

  $('.schedule-button').on('click tap', function () {
    $('.schedule-button').toggleClass('active');

    window.setTimeout(function () {
      $('.schedule-button').toggleClass('active');
    }, 200);
  });
});

},{}],2:[function(require,module,exports){
'use strict';

$(window).on('load resize scroll', function () {
  var newMargin = $('.neighborhood-container').css('margin-left');
  $('.bg-image-wrapper').css('width', 'calc(100% - ' + newMargin + ')');
});

},{}],3:[function(require,module,exports){
'use strict';

// Adds a method to jquery. Method called isInViewport, returns a boolean, and verifies if the selected element is in the viewport. This method has as vertical offset of 300px. This may cause elements to return 'true' at a 'later' experience.
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top + $(window).height();
  var elementBottom = elementTop + $(this).outerHeight(true);

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  // return elementBottom > viewportTop && elementTop < viewportBottom
  return $(this).offset().top <= $(window).scrollTop() + $(window).height();
};

$('.click-me').on('click', function () {
  $('.intro-svg').toggleClass('draw-itself');
});

$(window).on('scroll resize', function () {
  $('.svg-line').each(function (i, e) {
    if ($(this).isInViewport()) {
      $(this).addClass('draw-itself');
    } else {
      $(this).removeClass('draw-itself');
    }
  });
});

},{}],4:[function(require,module,exports){
'use strict';

var vpDetect = require('./custom/vpDetect.js');
var nav = require('./custom/navbar.js');
var resize = require('./custom/resize.js');
// const api = require('./custom/yardi/api.js')
// const events = require('./custom/yardi/events.js')
// const ui = require('./custom/yardi/ui.js')

},{"./custom/navbar.js":1,"./custom/resize.js":2,"./custom/vpDetect.js":3}]},{},[4]);
